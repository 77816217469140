import * as React from 'react';
import { useEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import { Button, Grid, Container, Link, Typography } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import { COLORS as Color } from "../Common/Constant/Color";
import flag1 from "../assets/img/contact/flag1.png"
import flag2 from "../assets/img/contact/flag2.png"
import flag3 from "../assets/img/contact/flag3.png"
import flag4 from "../assets/img/contact/flag4.png"
import flag5 from "../assets/img/contact/flag5.png"
import flag6 from "../assets/img/contact/flag6.png"
import flag7 from "../assets/img/contact/flag7.png"
import flag8 from "../assets/img/contact/flag8.png"
import flag9 from "../assets/img/contact/flag9.png"
import flag10 from "../assets/img/contact/flag10.png";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { styled } from '@mui/material/styles';

const Contacts = () => {
    const SendButton = styled(Button)(({ theme }) => ({
        color: Color.dark,
        backgroundColor: Color.white,
        fontSize: 23,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        border: `1px solid ${Color.dark}`,
        width: '100%',
        '&:hover': {
            backgroundColor: Color.dark,
            color: Color.white,
        },
    }));
    const [message, setMessage] = React.useState("");
    const [subject, setSubject] = React.useState("");
    useEffect(() => {


        var root = am5.Root.new("chartdiv", am5map.MapChart);
        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        // root.setThemes([am5themes_Animated.new(root)]);

        // Create the map chart
        // https://www.amcharts.com/docs/v5/charts/map-chart/
        var chart = root.container.children.push(
            am5map.MapChart.new(root, {
                panX: "rotateX",
                projection: am5map.geoEquirectangular()
            })
        );

        var cont = chart.children.push(
            am5.Container.new(root, {
                layout: root.horizontalLayout,
                x: 20,
                y: 40
            })
        );

        // Create series for background fill
        // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/#Background_polygon
        var backgroundSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {}));
        backgroundSeries.mapPolygons.template.setAll({
            fill: am5.color(0xE6E9EB),
            fillOpacity: 0,
            strokeOpacity: 0
        });

        // Add background polygon
        // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/#Background_polygon
        // backgroundSeries.data.push({
        //     geometry: am5map.getGeoRectangle(90, 180, -90, -180)
        // });

        // Create main polygon series for countries
        // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
        var polygonSeries = chart.series.push(
            am5map.MapPolygonSeries.new(root, {
                geoJSON: am5geodata_worldLow,
                exclude: ["AQ"],
                fill: am5.color(0xE6E9EB), //Map background color

            })
        );

        polygonSeries.mapPolygons.template.setAll({
            tooltipText: "{name}",
            templateField: "polygonSettings"
        });

        polygonSeries.mapPolygons.template.states.create("hover", {
            fill: am5.color(0xCCE5E7)
        });


        // Create line series for trajectory lines
        // https://www.amcharts.com/docs/v5/charts/map-chart/map-line-series/
        var lineSeries = chart.series.push(am5map.MapLineSeries.new(root, {}));
        lineSeries.mapLines.template.setAll({
            stroke: am5.color(0xE6E9EB),
            strokeOpacity: 0.3
        });

        // Create point series for markers
        // https://www.amcharts.com/docs/v5/charts/map-chart/map-point-series/
        var pointSeries = chart.series.push(am5map.MapPointSeries.new(root, {}));

        pointSeries.bullets.push(function () {
            var container = am5.Container.new(root, {
                tooltipText: "{title}",
                cursorOverStyle: "pointer",
            });

            // container.events.on("click", (e) => {
            //     window.location.href = e.target.dataItem.dataContext.url;
            // });

            var circle = container.children.push(
                am5.Circle.new(root, {
                    radius: 3,
                    tooltipY: 0,
                    fill: am5.color(0x007D88),
                    strokeOpacity: 0
                })
            );


            var circle2 = container.children.push(
                am5.Circle.new(root, {
                    radius: 3,
                    tooltipY: 0,
                    fill: am5.color(0x007D88),
                    strokeOpacity: 0,
                    tooltipText: "{title}"
                })
            );

            circle.animate({
                key: "scale",
                from: 1,
                to: 5,
                duration: 1000,
                easing: am5.ease.out(am5.ease.cubic),
                loops: Infinity
            });
            circle.animate({
                key: "opacity",
                from: 1,
                to: 0,
                duration: 1000,
                easing: am5.ease.out(am5.ease.cubic),
                loops: Infinity
            });

            return am5.Bullet.new(root, {
                sprite: container
            });
        });

        var cities = [
            {
                title: "United Kingdom",
                latitude: 55.3781,
                longitude: -3.4360,
                url: "http://www.amcharts.com",
                color: '#007D88'
            },
            {
                title: "Spain",
                latitude: 40.4168,
                longitude: -3.7038,
                url: "http://www.amcharts.com",
                color: '#007D88'
            },
            {
                title: "Finland",
                latitude: 61.9241,
                longitude: 25.7482,
                url: "http://www.amcharts.com",
                color: '#007D88'
            },
            {
                title: "Singapore",
                latitude: 1.290270,
                longitude: 103.851959,
                url: "http://www.amcharts.com",
                color: '#007D88'
            },
            {
                title: "Sri Lanka",
                latitude: 6.927079,
                longitude: 79.861244,
                url: "http://www.amcharts.com",
                color: '#007D88'
            },
            {
                title: "Malaysia",
                latitude: 4.2105,
                longitude: 101.9758,
                url: "http://www.amcharts.com",
                color: '#007D88'
            },
            {
                title: "Denmark",
                latitude: 56.2639,
                longitude: 9.5018,
                url: "http://www.amcharts.com",
                color: '#007D88'
            },
            {
                title: "India",
                latitude: 28.7041,
                longitude: 77.1025,
                url: "http://www.amcharts.com",
                color: '#007D88'
            },
            {
                title: "Pakistan",
                latitude: 33.6995,
                longitude: 73.0363,
                url: "http://www.amcharts.com",
                color: '#007D88'
            },
            {
                title: "Bangladesh",
                latitude: 23.8041,
                longitude: 90.4152,
                url: "http://www.amcharts.com",
                color: '#007D88'
            },
        ];

        for (var i = 0; i < cities.length; i++) {
            var city = cities[i];
            addCity(city.longitude, city.latitude, city.title, city.url);
        }

        function addCity(longitude, latitude, title, url) {
            pointSeries.data.push({
                url: url,
                geometry: { type: "Point", coordinates: [longitude, latitude] },
                title: title
            });
        }

        // Make stuff animate on load
        chart.appear(1000, 100);

        return () => root.dispose();
    }, []);
    const flags = [
        {
            url: flag1,
            country: 'flag',
        },
        {
            url: flag2,
            country: 'flag',
        },
        {
            url: flag3,
            country: 'flag',
        },


        {
            url: flag4,
            country: 'flag',
        },
        {
            url: flag5,
            country: 'flag',
        },
        {
            url: flag6,
            country: 'flag',
        },
        {
            url: flag7,
            country: 'flag',
        },
        {
            url: flag8,
            country: 'flag',
        },
        {
            url: flag9,
            country: 'flag',
        },
        {
            url: flag10,
            country: 'flag',
        }
    ];
    return (
        <div style={{
            fontSize: ' calc(10px + 2vmin)',
            fontFamily: 'Helvetica',
            marginTop: '80px',
            color: Color.black
        }}>

            <h1 className="gradient-text" style={{ fontSize: 40, textTransform: 'capitalize' }}>connect with LYXUX</h1>

            <h2 style={{ color: Color.black, fontSize: 35, textTransform: 'capitalize', margin: '70px 0px 10px 0' }}>Our Global Presence</h2>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item sm={6} xs={12}>
                    <Typography sx={{ textTransform: 'capitalize', margin: '10px 0' }}>We proudly serve clients in multiple countries. Check out the map below to see the regions we operate in. If you are in one of these areas, we are just a call or click away.</Typography>
                </Grid>
            </Grid>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center" spacing={1}>

                {flags.map((flag, index) => (
                    <Grid item xs={1}>
                        <img style={{ width: '100%' }} src={flag.url} alt="" />
                    </Grid>

                ))}

            </Grid>
            <div id="chartdiv" style={{ width: "100%", height: "300px" }} />
            <Container>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <Grid item
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3} sm={6} xs={12} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                        <h1 className="gradient-text" style={{ fontSize: 40, textTransform: 'capitalize' }}>Stay Connected</h1>
                        <p>Follow us on social media for the latest updates and industry insights.</p>
                        <Grid container
                            direction="row"
                            justifyContent={{ xs: 'center', sm: 'flex-start' }}
                            alignItems={{ xs: 'center', sm: 'flex-start' }} style={{ width: { md: '80%', sm: '100%', xs: '100%' }, marginTop: '20px' }}>

                            <Link href="https://www.linkedin.com/company/lyxux/"
                                underline="none" color="inherit">
                                <LinkedInIcon sx={{ color: Color.gray, fontSize: 30, margin: '0 5px' }} />
                            </Link>
                            <Link href="https://x.com/lyxuxtech"
                                underline="none" color="inherit">
                                <TwitterIcon sx={{ color: Color.gray, fontSize: 30, margin: '0 5px' }} />
                            </Link>
                            <Link href="https://www.facebook.com/share/7kVC56CH7bEpfUrh/?mibextid=LQQJ4d"
                                underline="none" color="inherit">
                                <FacebookIcon sx={{ color: Color.gray, fontSize: 30, margin: '0 5px' }} />
                            </Link>
                            <Link href="https://instagram.com/lyxux_ltd?igsh=MXVwdXljYzBhMng1NQ%3D%3D&utm_source=qr" underline="none"
                                color="inherit">
                                <InstagramIcon sx={{ color: Color.gray, fontSize: 30, margin: '0 5px' }} />
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3} sm={6} xs={12} sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
                        <h1 className="gradient-text" style={{ fontSize: 40, textTransform: 'capitalize' }}>Ask Us Anything!
                        </h1>
                        <p>Fill Out the Form Below and We'll Be in Touch Soon.</p>
                        <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Your Name</label>
                        <OutlinedInput size="small" style={textfieldStyle} />
                        <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Email</label>
                        <OutlinedInput size="small" style={textfieldStyle} />
                        <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Contact Number</label>
                        <OutlinedInput size="small" style={textfieldStyle} value={subject}
                            onChange={(e) => setSubject(e.target.value)} />
                        <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Your Requirement</label>
                        <OutlinedInput style={textfieldStyle} multiline
                            rows={4}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)} />
                        <SendButton onClick={() => {
                            window.location.href = `mailto:info@ilyxux.com?subject=${subject}&body=${message}`;
                        }}
                        >Send</SendButton>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

const textfieldStyle = {
    border: 'double 1px transparent',
    backgroundImage: `linear-gradient(white, white),  linear-gradient(to right, ${Color.purple},${Color.grayBlue},${Color.lightBlue})`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    width: '100%',
    borderRadius: 10,
    margin: '5px 0 10px 0',
};
export default Contacts;
