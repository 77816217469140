import React from 'react';
import { Button, Grid, Container } from "@mui/material";
import { COLORS as Color } from "../Constant/Color";
import { useNavigate } from "react-router-dom";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const SendButton = styled(Button)(({ theme }) => ({
    color: Color.dark,
    backgroundColor: Color.white,
    fontSize: 23,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    border: `1px solid ${Color.dark}`,
    width: '100%',
    '&:hover': {
        backgroundColor: Color.dark,
        color: Color.white,
    },
}));
const ServiceCard = ({ index, img, title, content }) => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container>
            <Grid container
                direction={index % 2 === 0 ? 'row' : "row-reverse"}
                justifyContent="center"
                alignItems="center" spacing={1} style={{ marginTop: "20px" }}>

                <Grid item md={8} sm={6} xs={12} sx={{ textAlign: { xs: 'center', sm: index % 2 === 0 ? 'left' : 'right' } }}>
                    <AnimationOnScroll initiallyVisible={index === 0 ? true : false} animateOnce={true} animateIn={index % 2 === 0 ? "animate__fadeInLeft" : "animate__fadeInRight"}>
                        <h1 className="gradient-text" style={{ fontSize: 40, textTransform: 'capitalize' }}>{title}</h1>
                        <p style={{ fontSize: 18, textTransform: 'capitalize', lineHeight: 2 }} className="card-content">{content}</p>
                        <Button variant="outlined" style={{
                            color: Color.headingColor,
                            fontSize: 20,
                            fontWeight: 'bold',
                            textTransform: 'capitalize',
                            borderColor: Color.headingColor,
                            padding: '0 10px'
                        }} onClick={handleClickOpen}
                        // onClick={() => {
                        //     window.scrollTo(0, 0);
                        //     navigate('/service')
                        // }}
                        >Get Quote</Button>
                    </AnimationOnScroll>
                </Grid>

                <Grid item md={4} sm={6} xs={12} sx={{
                    textAlign: {
                        xs: 'center',
                        sm: index % 2 === 0 ? 'right' : 'left',
                    }
                }}
                >
                    <AnimationOnScroll animateOnce={true} initiallyVisible={index === 0 ? true : false} animateIn={index % 2 === 0 ? "animate__fadeInRight" : "animate__fadeInLeft"}>
                        <img  src={img} alt="" />
                    </AnimationOnScroll>
                </Grid>

            </Grid>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={5}
                    >
                        <Grid item
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={3} xs={6} style={{ textAlign: 'left' }}>
                            <h1>Get Your Free Quote Today</h1>
                            <p>We Are Exited To Provide You With A Customized Quote Tailored To Your Needs. Please Fill Out THe Form Below, And Our Team Will Get Back To You Shortly With All The Details.</p>
                            <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Your Name</label>
                            <OutlinedInput size="small" style={textfieldStyle} />
                            <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Email</label>
                            <OutlinedInput size="small" style={textfieldStyle} />
                            <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Contact Number</label>
                            <OutlinedInput size="small" style={textfieldStyle} />
                            <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Your Requirement</label>
                            <OutlinedInput style={textfieldStyle} multiline
                                rows={4}
                            />
                            <SendButton onClick={() => {
                                window.location.href = `mailto:info@ilyxux.com?`;
                            }}
                            >Send</SendButton>
                        </Grid>
                    </Grid>
                </DialogContent>

            </BootstrapDialog>
        </Container>
    );
};
const textfieldStyle = {
    border: 'double 1px transparent',
    backgroundImage: `linear-gradient(white, white),  linear-gradient(to right, ${Color.purple},${Color.grayBlue},${Color.lightBlue})`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    width: '100%',
    borderRadius: 10,
    margin: '5px 0 10px 0',
};

export default ServiceCard;
