import { Button, Container, Grid, Link, Typography } from "@mui/material";
import * as React from "react";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LOGO from "../../assets/img/footerLyxuxLogo.png";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { COLORS as Color } from "../Constant/Color";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';

const Footer = () => {
    const navigate = useNavigate();


    return (
        <footer style={{ backgroundColor: 'white', fontFamily: 'Helvetica', }}>
            <hr style={{ marginTop: 50 }} />
            <Grid style={{ maxWidth: '100%' }}>
                <Grid
                    container
                    direction="row"
                    spacing={1}
                >
                    <Grid container
                        direction="column"
                        justifyContent="center"
                        alignItems="center" item xs={12} sm={5} md={2}
                        sx={{
                            textAlign: {
                                xs: 'center',
                                sm: 'left',
                            }, width: '100%'
                        }}>
                        <img src={LOGO} style={{ width: '80%' }} alt={'logo'} />

                        <Grid container
                            direction="row"
                            justifyContent="center"
                            alignItems="center" style={{ width: '80%', marginTop: '20px' }}>
                            <Link href="https://www.linkedin.com/company/lyxux/"
                                underline="none" color="inherit">
                                <LinkedInIcon sx={{ color: Color.gray, fontSize: 30, margin: '0 5px' }} />
                            </Link>
                            <Link href="https://x.com/lyxuxtech"
                                underline="none" color="inherit">
                                <TwitterIcon sx={{ color: Color.gray, fontSize: 30, margin: '0 5px' }} />
                            </Link>
                            <Link href="https://www.facebook.com/share/7kVC56CH7bEpfUrh/?mibextid=LQQJ4d"
                                underline="none" color="inherit">
                                <FacebookIcon sx={{ color: Color.gray, fontSize: 30, margin: '0 5px' }} />
                            </Link>
                            <Link href="https://instagram.com/lyxux_ltd?igsh=MXVwdXljYzBhMng1NQ%3D%3D&utm_source=qr" underline="none"
                                color="inherit">
                                <InstagramIcon sx={{ color: Color.gray, fontSize: 30, margin: '0 5px' }} />
                            </Link>
                        </Grid>
                    </Grid>


                    <Grid item xs={12} sm={3} md={2} sx={{
                        textAlign: {
                            xs: 'center',
                            sm: 'left',
                        }
                    }}>
                        <h2 style={{ color: Color.black, fontSize: 15, }}>Our Services</h2>
                        <Container
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                color: Color.black,
                                fontSize: 15,
                                lineHeight: 3,

                                fontFamily: 'Helvetica',
                            }}>

                            <Link href="#/services" underline="none" color="inherit" style={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate('#/services')
                                }}>IOS Development</Link>


                            <Link href="#/services" underline="none" color="inherit" style={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate('#/services')
                                }}>Mobile Development</Link>


                            <Link href="#/services" underline="none" color="inherit" style={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate('#/services')
                                }}>enterprise solutions</Link>


                            <Link href="#/services" underline="none" color="inherit" style={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate('#/services')
                                }}>blockchain development</Link>


                        </Container>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2} x={{
                        textAlign: {
                            xs: 'center',
                            sm: 'left',
                        }
                    }}>
                        <Typography sx={{
                            color: Color.white, fontSize: 15,
                            display: {
                                xs: 'none',
                                sm: 'block',
                            }
                        }} >Our Services</Typography>
                        <Container
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                color: Color.black,
                                fontSize: 15,
                                lineHeight: 3,
                                fontFamily: 'Helvetica',
                            }}>

                            <Link href="#/services" underline="none" color="inherit" style={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate('#/services')
                                }}>iot solutions</Link>


                            <Link href="#/services" underline="none"
                                color="inherit" style={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate('#/services')
                                }}>Web Development</Link>


                            <Link href="#/services" underline="none" color="inherit" style={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate('#/services')
                                }}>digital marketing</Link>


                            <Link href="#/services" underline="none" color="inherit" style={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate('#/services')
                                }}>multimedia creations</Link>


                        </Container>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2} x={{
                        textAlign: {
                            xs: 'center',
                            sm: 'left',
                        }
                    }}>
                        <h2 style={{ color: Color.black, fontSize: 15, }}>QUICK LINKS</h2>
                        <Container
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                color: Color.black,
                                fontSize: 15,
                                lineHeight: 3,
                                fontFamily: 'Helvetica',
                            }}>

                            <Link href="#/" underline="none" color="inherit" style={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate('#/')
                                }}>Home</Link>


                            <Link href="#/services" underline="none" color="inherit" style={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate('#/services')
                                }}>Services</Link>


                            <Link href="#/career" underline="none" color="inherit" style={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate('#/career')
                                }}>Careers</Link>


                        </Container>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2} x={{
                        textAlign: {
                            xs: 'center',
                            sm: 'left',
                        }
                    }}>
                        <Typography sx={{
                            color: Color.white, fontSize: 15,
                            display: {
                                xs: 'none',
                                sm: 'block',
                            }
                        }} >Quick Links</Typography>
                        <Container
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                color: Color.black,
                                fontSize: 15,
                                lineHeight: 3,
                                fontFamily: 'Helvetica',
                            }}>

                            <Link href="#/about" underline="none" color="inherit" style={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate('#/about')
                                }}>About Us</Link>


                            <Link href="#/contact" underline="none" color="inherit" style={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate('#/contact')
                                }}>Contact Us</Link>



                        </Container>
                    </Grid>
                    <Grid item xs={12} sm={7} md={2} x={{
                        textAlign: {
                            xs: 'center',
                            sm: 'left',
                        }
                    }}>
                        <Grid container
                            direction="row" style={{ padding: '20px 0' }}>
                            <Grid item xs={12} sm={2}>
                                <PhoneIcon sx={{ color: Color.black, fontSize: 30 }} />
                            </Grid>
                            <Grid item xs={12} sm={10}
                                style={{ color: Color.regular, fontSize: 20 }}>
                                <Link href="https://wa.me/+447915611988" underline="none" color="inherit" style={{ color: Color.black, fontSize: 15, fontFamily: 'Helvetica', }}
                                    onClick={() => {
                                        window.location.href = `https://wa.me/+447915611988 `;
                                    }}><Typography>+44 79 156 119 88 </Typography></Link>
                                <Link href="https://wa.me/+34603845546" underline="none" color="inherit" style={{ color: Color.black, fontSize: 15, fontFamily: 'Helvetica', }}
                                    onClick={() => {
                                        window.location.href = `https://wa.me/+34603845546`;
                                    }}>+34 603 84 55 46</Link>
                            </Grid>
                        </Grid>

                        <Grid container
                            direction="row" style={{ padding: '20px 0' }}>
                            <Grid item xs={12} sm={2}>
                                <EmailIcon sx={{ color: Color.black, fontSize: 30 }} />
                            </Grid>
                            <Grid item xs={12} sm={10}
                                style={{
                                    color: Color.black,
                                    fontSize: 15,
                                    fontFamily: 'Helvetica',
                                }} onClick={() => {
                                    window.location.href = `mailto:info@ilyxux.com?`;
                                }}>info@lyxux.com
                            </Grid>
                        </Grid>
                        <Grid container
                            direction="row" style={{ padding: '20px 0' }}>
                            <Grid item xs={12} sm={2}>
                                <LocationOnIcon sx={{ color: Color.black, fontSize: 30 }} />
                            </Grid>
                            <Grid item xs={12} sm={10}
                                style={{ color: Color.black, fontSize: 15, fontFamily: 'Helvetica', }}>London, United Kingdom</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Container style={{ backgroundColor: '#0F0D25', minWidth: '100%', margin: '10px 0 0 0' }}>
                <Typography style={{ color: Color.white, width: '100%' }}>Copyright © 2024 LYXUX LTD All rights reserved</Typography>
            </Container>
        </footer>
    )
}

export default Footer;
