import * as React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LOGO from "../../assets/img/lyxuxLogo.png";
import { Button } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { COLORS as Color } from "../Constant/Color";
import { styled } from '@mui/material/styles';

const navItems = [
    { name: 'HOME', nav: '/' },
    { name: 'ABOUT US', nav: '/about' },
    { name: 'SERVICES', nav: '/services' },
    { name: 'CAREERS', nav: '/career' },
    { name: 'CONTACT US', nav: '/contact' },]

const ColorButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Inter',
    fontWeight: 700,
    borderRadius: 150,
    padding: '8px 25px',
    backgroundImage: `linear-gradient(to right, ${Color.purple},${Color.grayBlue},${Color.lightBlue})`,
    // backgroundColor: purple[500],
    // '&:hover': {
    //     backgroundColor: purple[700],
    // },
}));
const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    color: Color.dark,
    fontFamily: 'Be Vietnam Pro',
    fontWeight: 400
}));

const Header = () => {
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>

            <Typography variant="h6" sx={{ my: 2 }}>
                <img src={LOGO} style={{ width: '80px' }} alt={'logo'} />
            </Typography>
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.name}>
                        <ListItemButton sx={{ textAlign: 'center', fontWeight: 400 }}>
                            <StyledListItemText primary={item.name} onClick={() => {
                                window.scrollTo(0, 0);
                                navigate(item.nav)
                            }} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Box sx={{ display: "flex" }}>
            <AppBar component="nav" style={{ backgroundColor: 'white', boxShadow: 'none' }}>
                <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon style={{ color: Color.black }} />
                    </IconButton>
                    <img src={LOGO} style={{ width: '60px' }} alt={'logo'} />
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <Button style={{ color: Color.black, fontFamily: 'Be Vietnam Pro', fontWeight: 400 }} key={item.name}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate(item.nav)
                                }} children={item.name} />
                        ))}
                    </Box>
                    <Button>
                        <ColorButton variant="contained">LET'S PAY!</ColorButton>
                    </Button>
                </Toolbar>
            </AppBar>
            <Box component="nav" style={{ backgroundColor: Color.theme }}>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240, backgroundColor: Color.theme },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}

export default Header;
