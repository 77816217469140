import * as React from 'react';
import './css/career.css';
import { COLORS as Color } from "../Common/Constant/Color";
import bag from "../assets/img/careers/bag.png";
import { Grid } from "@mui/material";
import 'react-phone-input-2/lib/style.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


const Career = () => {
    const [message, setMessage] = React.useState("");
    const [subject, setSubject] = React.useState("");
    return (
        <div style={{
            fontSize: ' calc(10px + 2vmin)',
            fontFamily: 'Helvetica',
            marginTop: '80px',
            color: Color.black
        }}>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center" spacing={1} style={{ marginTop: "20px" }}>

                <Grid item xs={12}>
                    <div style={{ textAlign: 'center', padding: '0 10%' }}>

                        <h1 className="gradient-text" style={{ fontSize: 40, }}>Careers At LYXUX</h1>
                        <p style={{ color: Color.headingColor, fontSize: 23, lineHeight: '40px', textTransform: 'capitalize', }}>At LYXUX, we believe in empowering our employees to grow and succeed. Our team is our greatest asset, and we are always looking for talented, driven individuals to join us.</p>

                    </div>
                </Grid>
            </Grid>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center" spacing={1} style={{ marginTop: "20px" }}>
                <Grid item xs={10} sm={6}>
                    <Card >

                        <CardContent>
                            <img style={{ width: "80px" }} src={bag} alt="" />
                            <p>
                                <b> we are sorry.....we are no hiring at the moment.</b>
                            </p>
                            <p>Feel free to reach out to our HR team at <u>info@lyxux.com</u> with any questions or to express your interest in future opportunities.</p>
                        </CardContent>

                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}
const ButtonStyle = {
    backgroundColor: Color.theme,
    margin: '5px 0 20px 0',
    color: Color.white,
    fontFamily: 'Baskervville',
    fontSize: 15,
    padding: 15,
    borderRadius: 100
};


export default Career;
