import * as React from 'react';
import Carousel from 'better-react-carousel';
import './css/home.css'
import main from "../assets/img/home/main.png";
import service1 from "../assets/img/home/service1.png";
import service2 from "../assets/img/home/service2.png";
import service3 from "../assets/img/home/service3.png";
import service4 from "../assets/img/home/service4.png";
import service5 from "../assets/img/home/service5.png";
import service6 from "../assets/img/home/service6.png";
import service7 from "../assets/img/home/service7.png";
import service8 from "../assets/img/home/service8.png";
import { Button, Grid, Container, Typography } from "@mui/material";
import { COLORS as Color } from "../Common/Constant/Color";
import { useNavigate } from "react-router-dom";
import OutlinedInput from '@mui/material/OutlinedInput';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'react-phone-input-2/lib/style.css';
import './css/career.css';
import brand1 from "../assets/img/brands/LYXUX.png";
import brand2 from "../assets/img/brands/logo2.png";
import brand3 from "../assets/img/brands/Imrooka ayurvedic and beauty LOGO.png";
import brand4 from "../assets/img/brands/beeinmindlogo copy.png";
import brand5 from "../assets/img/brands/Untitled-1.png";
import brand6 from "../assets/img/brands/jexplore.png";
import brand7 from "../assets/img/brands/ECO.png";
import brand8 from "../assets/img/brands/edu.png";
import brand9 from "../assets/img/brands/impulzetradetemp.png";
import brand10 from "../assets/img/brands/IMROOKAGLOBALCARE.png";
import brand11 from "../assets/img/brands/JGAMEZONE.png";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const Home = () => {
    const brands = [

        {
            url: brand2,
            title: 'brand2',
            width: '20%',
        },
        {
            url: brand3,
            title: 'brand3',
            width: '20%',
        },
        {
            url: brand4,
            title: 'brand4',
            width: '20%',
        },
        {
            url: brand5,
            title: 'brand5',
            width: '20%',
        },
        {
            url: brand6,
            title: 'brand6',
            width: '20%',
        },
        {
            url: brand7,
            title: 'brand7',
            width: '20%',
        },
        {
            url: brand8,
            title: 'brand8',
            width: '20%',
        },
        {
            url: brand9,
            title: 'brand9',
            width: '20%',
        },
        {
            url: brand10,
            title: 'brand10',
            width: '20%',
        },
        {
            url: brand11,
            title: 'brand11',
            width: '20%',
        },
    ];
    const services = [
        {
            url: service1,
            title: 'IOS Development',
            description: 'LYXUX offers advanced iOS app development, utilizing the latest technologies and industry best practices to create captivating mobile experiences for Apple devices, delivering high-quality, engaging apps that drive success.',
        },
        {
            url: service2,
            title: 'web development',
            description: 'Our web development solutions focus on creating visually stunning, user-friendly, and functional websites, ensuring an exceptional online experience for visitors through responsive design and seamless navigation.',
        },
        {
            url: service3,
            title: 'mobile development',
            description: 'LYXUX offers advanced iOS app development, utilizing the latest technologies and industry best practices to create captivating mobile experiences for Apple devices, delivering high-quality, engaging apps that drive success.',
        },

        {
            url: service4,
            title: 'digital marketing',
            description: 'LYXUX offers comprehensive digital marketing services, optimizing websites for search engines, enhancing social media engagement, and employing data-driven strategies for maximum ROI and continuous growth.',
        },
        {
            url: service5,
            title: 'enterprise solutions',
            description: 'LYXUX Enterprise Solutions streamline business operations, enhance collaboration, and drive growth by integrating seamlessly into existing systems, empowering organizations to reach their full potential.',
        },
        {
            url: service6,
            title: 'blockchain development',
            description: 'LYXUX offers comprehensive services for blockchain technology, including development, smart contract implementation, and DApp creation, transforming business operations, enhancing data integrity, and facilitating secure transactions.',
        },
        {
            url: service7,
            title: 'multimedia creations',
            description: 'LYXUX offers comprehensive digital marketing services, optimizing websites for search engines, enhancing social media engagement, and employing data-driven strategies for maximum ROI and continuous growth.',
        },
        {
            url: service8,
            title: 'iot solutions',
            description: 'LYXUX offers comprehensive services in IoT, focusing on device communication, architecture design, and data analytics to drive innovation, optimize operations, and embrace the digital revolution.',
        },

    ];
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const navigate = useNavigate();
    const [message, setMessage] = React.useState("");
    const [subject, setSubject] = React.useState("");


    const MainButton = styled(Button)(({ theme }) => ({
        backgroundColor: Color.white,
        color: Color.dark,
        fontSize: 23,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        border: `1px solid ${Color.dark}`,
        '&:hover': {
            backgroundColor: Color.dark,
            color: Color.white,
        },
    }));
    const SendButton = styled(Button)(({ theme }) => ({
        color: Color.dark,
        backgroundColor: Color.white,
        fontSize: 23,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        border: `1px solid ${Color.dark}`,
        width: '100%',
        '&:hover': {
            backgroundColor: Color.dark,
            color: Color.white,
        },
    }));
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{
            fontSize: ' calc(10px + 2vmin)',
            fontFamily: 'Helvetica',
            marginTop: '80px'
        }}>

            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center" spacing={1}>

                <Grid item xs={12} md={6} sx={{ textAlign: { md: 'left', sm: 'center' } }}>
                    <Grid sx={{ paddingLeft: { sm: 5, xs: 0 } }}>
                        <h1 style={{ color: Color.headingColor, margin: 0, lineHeight: '70px' }}>Unleash the Power of</h1>

                        <h1 className="gradient-text" style={{
                            fontSize: 60, margin: 0, lineHeight: '75px'
                        }}>Innovation Software Solutions</h1>

                        <p style={{ color: Color.headingColor, fontSize: 25, lineHeight: '40px' }}>
                            Our mission is to deliver cutting-edge software solutions that drive business growth, enhance productivity, and create exceptional user experiences.
                        </p>

                        <MainButton onClick={handleClickOpen}>Free Consultation</MainButton>


                        {/* <Button variant="outlined" style={ButtonStyle} onClick={() => {
                            window.scrollTo(0, 0);
                            navigate('/about')
                        }}
                        >Free Consultation</Button> */}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} sx={{ textAlign: 'right', display: { md: 'block', sm: 'none', xs: 'none' } }}>
                    <img style={{ width: "85%" }} src={main} alt="" />
                </Grid>
            </Grid>

            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center" spacing={1} style={{ marginTop: "20px" }}>

                    <Grid item xs={12}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <h1 style={{ color: Color.black, fontSize: 40, }}>WELCOME TO LYXUX LTD</h1>
                            <p style={{ color: Color.headingColor, fontSize: 23, lineHeight: '40px', textTransform: 'capitalize', }}>We strive to be the preferred technology partner, providing <b>customized solutions</b> and unmatched support to help businesses thrive in the digital era. Together, we <b>innovate, transform,</b> and <b>achieve</b> remarkable success.</p>
                            <Button variant="contained" style={AboutButtonStyle} onClick={() => {
                                window.scrollTo(0, 0);
                                navigate('/about')
                            }}
                            >About Us</Button>

                        </div>
                    </Grid>
                </Grid>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                <Container>
                    <Grid style={{ paddingBottom: '20px', marginTop: '100px', }}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={5}
                            style={{ overflow: "hidden" }}
                        >
                            <Grid item>
                                {/* <AnimationOnScroll animateIn="animate__fadeInRight"> */}
                                <h1 style={{ color: Color.black, fontSize: 40, }}>OUR SERVICES</h1>
                                {/* </AnimationOnScroll> */}


                            </Grid>
                            {/* <AnimationOnScroll animateIn="animate__fadeIn" style={{ width: '100%' }}> */}
                            <Grid sx={{
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                paddingLeft: 6
                            }} container spacing={5}>
                                {services.map((service) => (
                                    <Grid item xs={12} sm={6} md={3}>
                                        <div>
                                            <img style={{ height: '100px' }} src={service.url} alt="" />
                                            <Typography gutterBottom variant="h5" component="div">
                                                {service.title}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {service.description}
                                            </Typography>
                                            <Button variant="contained" style={AboutButtonStyle} onClick={() => {
                                                setOpen(true);
                                            }}
                                            >Get Quote</Button>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                            {/* </AnimationOnScroll> */}
                        </Grid>
                    </Grid>
                </Container>
            </AnimationOnScroll>
            <Grid>
                <Grid style={{ paddingBottom: '20px', marginTop: '100px' }}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={5}
                    >
                        <Grid item>
                            {/* <AnimationOnScroll animateIn="animate__fadeInRight"> */}
                            <h1 style={{ color: Color.black, fontSize: 40, }}>WHO WE WORK WITH</h1>
                            {/* </AnimationOnScroll> */}


                        </Grid>
                    </Grid>
                    {/* <AnimationOnScroll animateIn="animate__fadeIn" style={{width: '100%'}}> */}
                    <Container style={{
                        marginBottom: 10, width: '100%',
                    }}>
                        <Carousel cols={5} rows={1} gap={1} hideArrow={true} autoplay={1000} loop>
                            {brands.map((image) => (
                                <Carousel.Item>
                                    <img style={{ width: "80%" }} src={image.url} alt="" />
                                </Carousel.Item>
                            ))}

                        </Carousel>

                    </Container>
                    {/* </AnimationOnScroll> */}
                </Grid>
            </Grid>

            <h1 style={{ color: Color.black, fontSize: 40, textTransform: 'uppercase', marginTop: '130px' }}>Get in Touch with us</h1>
            <h1 className="gradient-text" style={{
                fontSize: 40, margin: 0, fontWeight: 'normal', marginTop: 80
            }}>Ask Us Anything! </h1>
            <p>Fill Out the Form Below and We'll Be in Touch Soon.</p>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={5}
            >
                <Grid item
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3} sm={6} xs={10} style={{ textAlign: 'left' }}>
                    <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Your Name</label>
                    <OutlinedInput size="small" style={textfieldStyle} />
                    <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Email</label>
                    <OutlinedInput size="small" style={textfieldStyle} />
                    <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Contact Number</label>
                    <OutlinedInput size="small" style={textfieldStyle} value={subject}
                        onChange={(e) => setSubject(e.target.value)} />
                    <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Your Requirement</label>
                    <OutlinedInput style={textfieldStyle} multiline
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)} />
                    <SendButton onClick={() => {
                        window.location.href = `mailto:info@ilyxux.com?subject=${subject}&body=${message}`;
                    }}
                    >Send</SendButton>
                </Grid>
            </Grid>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={5}
                    >
                        <Grid item
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={3} xs={6} style={{ textAlign: 'left' }}>
                            <h1>Get Your Free Quote Today</h1>
                            <p>We Are Exited To Provide You With A Customized Quote Tailored To Your Needs. Please Fill Out THe Form Below, And Our Team Will Get Back To You Shortly With All The Details.</p>
                            <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Your Name</label>
                            <OutlinedInput size="small" style={textfieldStyle} />
                            <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Email</label>
                            <OutlinedInput size="small" style={textfieldStyle} />
                            <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Contact Number</label>
                            <OutlinedInput size="small" style={textfieldStyle} />
                            <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Your Requirement</label>
                            <OutlinedInput style={textfieldStyle} multiline
                                rows={4}
                            />
                            <SendButton onClick={() => {
                                window.location.href = `mailto:info@ilyxux.com?`;
                            }}
                            >Send</SendButton>
                        </Grid>
                    </Grid>
                </DialogContent>

            </BootstrapDialog>
        </div >
    )
}

const AboutButtonStyle = {
    backgroundColor: Color.dark,
    padding: '4px 28px',
    color: Color.white,
    fontSize: 23,
    fontWeight: 'bold',
    textTransform: 'capitalize',
};
const textfieldStyle = {
    border: 'double 1px transparent',
    backgroundImage: `linear-gradient(white, white),  linear-gradient(to right, ${Color.purple},${Color.grayBlue},${Color.lightBlue})`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    width: '100%',
    borderRadius: 10,
    margin: '5px 0 10px 0',
};
export default Home;
