import './App.css';
import {Route, Routes, HashRouter} from 'react-router-dom';
import Header from "./Common/Layout/Header";
import Home from "./View/home";
import Footer from "./Common/Layout/Footer";
import About from "./View/about";
import Services from "./View/services";
import Contacts from "./View/contacts";
import Career from "./View/career";

function App() {
    return (
        <div className="App">
            <HashRouter>
                <Header/>
                <Routes>
                    <Route path={"/"} element={<Home/>}/>
                    <Route exact path={"/about"} element={<About/>}/>
                    <Route path={"/services"} element={<Services/>}/>
                    <Route path={"/career"} element={<Career/>}/>
                    <Route exact path={"/contact"} element={<Contacts/>}/>
                </Routes>
                <Footer/>
            </HashRouter>
        </div>
    );
}

export default App;
