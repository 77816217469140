import * as React from 'react';
import Carousel from 'better-react-carousel';
import './css/about.css';
import { Grid, Container, Typography } from "@mui/material";
import blueIcon from "../assets/img/about us/blueIcon.png";
import value1 from "../assets/img/about us/value1.png";
import value2 from "../assets/img/about us/value2.png";
import value3 from "../assets/img/about us/value3.png";
import value4 from "../assets/img/about us/value4.png";
import brand1 from "../assets/img/brands/LYXUX.png";
import brand2 from "../assets/img/brands/logo2.png";
import brand3 from "../assets/img/brands/Imrooka ayurvedic and beauty LOGO.png";
import brand4 from "../assets/img/brands/beeinmindlogo copy.png";
import brand5 from "../assets/img/brands/Untitled-1.png";
import brand6 from "../assets/img/brands/jexplore.png";
import brand7 from "../assets/img/brands/ECO.png";
import brand8 from "../assets/img/brands/edu.png";
import brand9 from "../assets/img/brands/impulzetradetemp.png";
import brand10 from "../assets/img/brands/IMROOKAGLOBALCARE.png";
import brand11 from "../assets/img/brands/JGAMEZONE.png";
import { COLORS as Color } from "../Common/Constant/Color";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useNavigate } from "react-router-dom";

const About = () => {
    const navigate = useNavigate();
    const brands = [
        {
            url: brand1,
            title: 'brand1',
            width: '20%',
        },
        {
            url: brand2,
            title: 'brand2',
            width: '20%',
        },
        {
            url: brand3,
            title: 'brand3',
            width: '20%',
        },
        {
            url: brand4,
            title: 'brand4',
            width: '20%',
        },
        {
            url: brand5,
            title: 'brand5',
            width: '20%',
        },
        {
            url: brand6,
            title: 'brand6',
            width: '20%',
        },
        {
            url: brand7,
            title: 'brand7',
            width: '20%',
        },
        {
            url: brand8,
            title: 'brand8',
            width: '20%',
        },
        {
            url: brand9,
            title: 'brand9',
            width: '20%',
        },
        {
            url: brand10,
            title: 'brand10',
            width: '20%',
        },
        {
            url: brand11,
            title: 'brand11',
            width: '20%',
        },
    ];

    return (
        <div style={{
            fontSize: ' calc(10px + 2vmin)',
            fontFamily: 'Helvetica',
            marginTop: '80px',
            color: Color.black
        }}>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center" spacing={1} style={{ marginTop: "20px" }}>

                <Grid item xs={12}>
                    <div style={{ textAlign: 'center', padding: '0 10%' }}>

                        <h1 className="gradient-text" style={{ fontSize: 40, }}>Who We Are</h1>
                        <p style={{ color: Color.headingColor, fontSize: 23, lineHeight: '40px', textTransform: 'capitalize', }}>LYXUX is a leading provider of innovative software solutions, combining expertise, creativity, and a client-centric approach to drive business growth and create exceptional user experiences.</p>
                        <img style={{ width: "80px" }} src={blueIcon} alt="" />

                    </div>
                </Grid>
            </Grid>


            <Container>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center" spacing={1} style={{ marginTop: "50px" }}>

                    <Grid item xs={12}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <h1 style={{ fontSize: 40, }}>Our Values.</h1>


                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <img style={{ width: "65%" }} src={value1} alt="" />
                            <h3>Quality</h3>

                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <img style={{ width: "65%" }} src={value2} alt="" />
                            <h3>Customer Focus</h3>

                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <img style={{ width: "65%" }} src={value3} alt="" />
                            <h3>Innovation</h3>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <img style={{ width: "65%" }} src={value4} alt="" />
                            <h3>Intergrity</h3>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <Typography sx={{ textTransform: 'capitalize', fontSize: 18 }}>These four principles highlight our fundamental beliefs as a team and as a business. Our core values attract and unite individuals focused on building a stable, desirable work environment and providing exceptional solutions we are proud of.</Typography>


                        </div>
                    </Grid>
                </Grid>
            </Container>

            {/*start vision & mission*/}
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn"
                style={{ marginTop: '100px', }}>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={10}
                    className={'cards'}
                    style={{
                        //   backgroundImage: `url(${blackBackground})`,
                        background: Color.white,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}>
                    <Grid item xs={12} sm={6} justifyContent="center"
                        alignItems="center" className={'wrapper'}>
                        <div className={'box'}>
                            <h2>Our Vision</h2>
                            <div style={{
                                // backgroundImage: `url(${vision})`,
                                backgroundPosition: 'center',
                                backgroundSize: '56%',
                                backgroundRepeat: 'no-repeat',
                            }}>
                                <p>At LYXUX, our vision is to be the leading provider of software worldwide, setting the standard for innovation, quality, and customer satisfaction. We aim to empower our customers by delivering solutions that enhance their lives and drive their success.</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} justifyContent="center"
                        alignItems="center" className={'wrapper'}>
                        <div className={'box'}>
                            <h2>Our Mission</h2>
                            <div style={{
                                // backgroundImage: `url(${mission})`,
                                backgroundPosition: 'center',
                                backgroundSize: '56%',
                                backgroundRepeat: 'no-repeat',
                            }}>
                                <p>Our mission is to revolutionize the software industry by offering cutting-edge software services that exceed expectations.</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </AnimationOnScroll>
            {/*end vission & mission*/}
            <Grid>
                <Grid style={{ paddingBottom: '20px', marginTop: '50px' }}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={5}
                    >
                        <Grid item>
                            {/* <AnimationOnScroll animateIn="animate__fadeInRight"> */}
                            <h1 style={{ color: Color.black, fontSize: 40, }}>WHO WE WORK WITH</h1>
                            {/* </AnimationOnScroll> */}


                        </Grid>
                    </Grid>
                    {/* <AnimationOnScroll animateIn="animate__fadeIn" style={{width: '100%'}}> */}
                    <Container style={{
                        marginBottom: 10, width: '100%',
                    }}>
                        <Carousel cols={5} rows={1} gap={1} hideArrow={true} autoplay={1000} loop>
                            {brands.map((image) => (
                                <Carousel.Item>
                                    <img style={{ width: "80%" }} src={image.url} alt="" />
                                </Carousel.Item>
                            ))}

                        </Carousel>

                    </Container>
                    {/* </AnimationOnScroll> */}
                </Grid>
            </Grid>
        </div>
    )
}
export default About;
