import * as React from 'react';
import './css/about.css';
import serv1 from "../assets/img/home/service1.png";
import serv2 from "../assets/img/home/service2.png";
import serv3 from "../assets/img/home/service3.png";
import serv4 from "../assets/img/home/service4.png";
import serv5 from "../assets/img/home/service5.png";
import serv6 from "../assets/img/home/service6.png";
import serv7 from "../assets/img/home/service7.png";
import serv8 from "../assets/img/home/service8.png";
import { COLORS as Color } from "../Common/Constant/Color";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useNavigate } from "react-router-dom";
import ServiceCard from '../Common/Component/ServiceCard'
import { TroubleshootSharp } from '@mui/icons-material';

const Services = () => {
    const navigate = useNavigate();
    const services = [
        {
            url: serv1,
            title: 'IOS development',
            description: 'LYXUX offers advanced iOS app development, utilizing the latest technologies and industry best practices to create captivating mobile experiences for Apple devices, delivering high-quality, engaging apps that drive success.',
        },
        {
            url: serv2,
            title: 'WEB development',
            description: 'Our web development solutions focus on creating visually stunning, user-friendly, and functional websites, ensuring an exceptional online experience for visitors through responsive design and seamless navigation.',
        },
        {
            url: serv3,
            title: 'mobile development',
            description: 'LYXUX offers advanced iOS app development, utilizing the latest technologies and industry best practices to create captivating mobile experiences for Apple devices, delivering high-quality, engaging apps that drive success.',
        },
        {
            url: serv4,
            title: 'digital marketing',
            description: 'LYXUX offers comprehensive digital marketing services, optimizing websites for search engines, enhancing social media engagement, and employing data-driven strategies for maximum ROI and continuous growth.',
        },


        {
            url: serv5,
            title: 'Enterprise solutions',
            description: 'LYXUX Enterprise Solutions streamline business operations, enhance collaboration, and drive growth by integrating seamlessly into existing systems, empowering organizations to reach their full potential.',
        },
        {
            url: serv6,
            title: 'blockchain development',
            description: 'LYXUX offers comprehensive services for blockchain technology, including development, smart contract implementation, and DApp creation, transforming business operations, enhancing data integrity, and facilitating secure transactions.',
        },
        {
            url: serv7,
            title: 'multimedia creations',
            description: 'LYXUX offers comprehensive digital marketing services, optimizing websites for search engines, enhancing social media engagement, and employing data-driven strategies for maximum ROI and continuous growth.',
        },
        {
            url: serv8,
            title: 'IOT solutions',
            description: 'LYXUX offers comprehensive services in IoT, focusing on device communication, architecture design, and data analytics to drive innovation, optimize operations, and embrace the digital revolution.',
        },
    ];
    return (
        <div style={{
            fontSize: ' calc(10px + 2vmin)',
            fontFamily: 'Helvetica',
            marginTop: '80px',
            color: Color.black,
            overflow:'hidden'
        }}>
            <AnimationOnScroll initiallyVisible={TroubleshootSharp} animatePreScroll={true} animateOnce={true} animateIn={"animate__fadeInTop"}>
                <h1 className="gradient-text" style={{ fontSize: 40, textTransform: 'capitalize' }}>what we offer</h1>
            </AnimationOnScroll>
            {services.map((service, index) => (
                <ServiceCard
                    index={index}
                    img={service.url}
                    title={service.title}
                    content={service.description} />
            ))}

        </div>
    )
}
export default Services;
